<template>
  <CSidebarNav>
    <div
      v-for="(item, idx) in items"
      :key="idx"
    >
      <CSidebarNavItem
        v-if="item.type === 'link'"
        activeClass="c-active"
        :icon="item.icon"
        :name="item.name"
        :active="isActiveItem(item)"
        @click.native.stop="goTo(item.to)"
      />
      <CSidebarNavDropdown
        v-else
        :icon="item.icon"
        :name="item.name"
        :show="isActiveItem(item) && !$store.state.sidebarMinimize"
      >
        <CSidebarNavItem
          v-for="(children, cidx) in item.children"
          :key="cidx"
          activeClass="c-active"
          :name="children.name"
          :icon="children.icon"
          :active="isActiveItem(children)"
          @click.native.stop="goTo(children.to)"
        />
      </CSidebarNavDropdown>
    </div>
  </CSidebarNav>
</template>

<script>
import buildMenu from './menu';

export default {
  name: 'SidebarItems',
  watch: {
    '$route': function(val) {
      console.log(val);
    }
  },
  computed: {
    items() {
      return buildMenu();
    }
  },
  methods: {
    goTo(name, options = {}) {
      if (this.$route.name == name) {
        return;
      }

      this.$router.push({name, ...options})
    },
    isActiveLink(link) {
      if (link === undefined) {
        return false;
      }
      return this.$route.name === link;
    },
    isActiveItem(item) {
      if (!item.children) {
        return this.isActiveLink(item.to);
      }
      if (item.children) {
        return item.children.some((child) => this.isActiveItem(child));
      }
      return false;
    }
  }
}
</script>
