<template>
  <CHeader
    fixed
    with-subheader
    light
  >
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />

    <CHeaderNav class="d-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/">
          <template>
            Администрирование
          </template>
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-down-none mx-2">
        <div>
          {{ $store.state.auth.user.email }}
        </div>
      </CHeaderNavItem>
      <account-dropdown />
    </CHeaderNav>
    <CSubheader class="px-3">
      <!-- <MainBreadcrumbs class="border-0 mb-0" /> -->
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>


<script>
import AccountDropdown from '../components/account-dropdown';
// import MainBreadcrumbs from './main-breadcrumbs';

export default {
  name: 'MainHeader',
  components: {
    AccountDropdown
  },
};
</script>
