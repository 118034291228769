import store from '@/store';
import { every, get } from 'lodash';

const isAdmin = (store) => store.getters.isServiceAdmin;

const menu = [
  {
    type: 'dropdown',
    icon: 'cil-notes',
    name: 'Документы',
    meta: {
      constraints: [isAdmin]
    },
    children: [
      {
        type: 'link',
        name: 'Заказы',
        to: 'admin.documents.customer_orders.index'
      },
      {
        type: 'link',
        name: 'Отгрузки',
        to: 'admin.documents.dispatches.index'
      },
      {
        type: 'link',
        name: 'Возвраты',
        to: 'admin.documents.returns.index'
      },
      {
        type: 'link',
        name: 'Перемещения',
        to: 'admin.documents.movements.index'
      },
      {
        type: 'link',
        name: 'Внутренние заказы',
        to: 'admin.documents.internal_orders.index'
      },
      {
        type: 'link',
        name: 'Оприходования',
        to: 'admin.documents.enters.index'
      }
    ],
  },
  {
    type: 'link',
    icon: 'cil-graph',
    name: 'Отчеты',
    to: 'admin.reports.index',
    meta: {

    }
  },
  {
    type: 'link',
    icon: 'cil-truck',
    name: 'Транзиты',
    to: 'admin.transits.index',
    meta: {
      constraints: [isAdmin]
    }
  },
  {
    type: 'link',
    icon: 'cil-house',
    name: 'Склады',
    to: 'admin.stores.index',
    meta: {
      constraints: [isAdmin]
    }
  },
  {
    type: 'dropdown',
    icon: 'cil-settings',
    to: '',
    name: 'Источники',
    meta: {
      constraints: [isAdmin]
    },
    children: [
      {
        type: 'link',
        to: 'admin.moysklad.setup',
        name: 'МойСклад'
      },
      {
        type: 'link',
        to: 'admin.warehouses.index',
        name: 'Фулфилмент'
      }
    ]
  }
];

const buildMenu = () => {
  return menu.filter(item => {
    const constraints = get(item, 'meta.constraints', [])
    console.log(store.getters.isServiceAdmin)
    return every(constraints, (predicate) => predicate(store))
  })
}

export default buildMenu;
