<template>
  <div class="c-app">
    <main-sidebar />
    <CWrapper>
      <main-header />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <router-view />
          </CContainer>
        </main>
        <main-footer />
      </div>
    </CWrapper>
  </div>
</template>


<script>
import MainFooter from './main-footer.vue';
import MainHeader from './main-header.vue';
import MainSidebar from './main-sidebar.vue';

export default {
  name: 'Container',

  components: {
    MainFooter,
    MainHeader,
    MainSidebar,
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
