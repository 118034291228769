<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand
      class="d-md-down-none"
      :to="{ name: 'admin.index' }"
    >
      <CIcon
        class="c-sidebar-brand-full"
        name="logo-minimized"
        size="custom-size"
        :height="45"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo-minimized"
        size="custom-size"
        :height="40"
      />
    </CSidebarBrand>

    <SidebarItems />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import SidebarItems from './sidebar-items';

export default {
  name: 'TheSidebar',
  components: {
    SidebarItems,
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
};
</script>

<style lang='scss' scoped>
.custom-text-logo {
  color: var(--cui-sidebar-nav-link-color, rgba(255, 255, 255, 0.6)) !important;
  margin-bottom: 0 !important;
  pointer-events: none;
}
</style>
